<template> 
<v-app>
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                    <v-card class="elevation-12">
                        <v-toolbar dark color="blue-grey darken-4">
                            <router-link style="cursor:pointer;" to="/">
                                <v-img height="30" position="left center" contain src="/img/logo_sun_n30.png" />
                            </router-link>
                            <v-spacer></v-spacer>
                            <v-icon color="grey" class="ml-n4">mdi-lock-question</v-icon>
                        </v-toolbar>
                        <v-card-title class="d-block text-center my-4">
                            Забули пароль?
                        </v-card-title>
                        <v-card-subtitle class="mb-4" v-if="!userInfo().id">
                            Для встановлення нового паролю вкажіть данні з профайлу:
                        </v-card-subtitle>
                        <v-card-text v-if="!userInfo().id">
                            <v-text-field  name="login" label="Користувач *" type="text" v-model="f.login"></v-text-field>
                            <v-text-field  label="Email *" type="text" v-model="f.email"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="mb-4">
                            <v-spacer></v-spacer>
                            <v-btn :disabled="!checkForm" @click="submitForm()" :loading="loading" color="primary">Нагадати</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        <v-card-text class="caption text-center py-2 grey lighten-3"><a href="https://yugcontract.ua" target="_blank">yugcontract.ua</a></v-card-text>
                    </v-card>

                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import {
    mask
} from 'vue-the-mask'

import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {
        mask
    },
    data: () => ({
        maskTel: '+38(###) ###-####',
        f: {},
        errorMessage: ''
    }),
    props: {},
    methods: {
        ...mapActions(['getNewClientPageData', 'forgotPassword', 'setSnackbar','getUserInfo']),
        ...mapGetters(['userInfo']),
        async submitForm() {
            // Execute reCAPTCHA with action "login".
            await this.$recaptchaLoaded()
            this.f.reCAPTCHA = await this.$recaptcha('newClient')
            this.forgotPassword(this.f)
                .then(data => {
                    if (data.status == 'ok') {
                        this.f = {}
                        this.$vuetify.goTo(0)
                        this.$router.push('/login')
                        this.setSnackbar(['success', 'Ваш пароль успішно змінено та надіслано на вказаний Email!'])
                    } else {
                        this.setSnackbar(['error', data.error])
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 417) {
                        this.errorMessage = 'Не пройдено перевірку безпеки. Оновіть сторінку та спробуйте ще раз.'
                    } else  if (error.response && error.response.status == 403) {
                        this.errorMessage = 'Данні не знайдено або Ваш доступ на портал заблокований.'
                    } else {
                        this.errorMessage = error.response.data && error.response.data.error && error.response.data.error.message ? error.response.data.error.message : 'Помилка сервера. Спробуйте пізніше.'
                    }
                    this.setSnackbar(['error', this.errorMessage])
                    console.log(error)
                })
        }
    },
    created() {
        this.getUserInfo()
    },
    computed: {
        checkForm() {
            // return true
            return this.f.login && this.f.email && this.f.email.match(/\w+\@\w+\.\w+/) ? true : false
        },
        loading() {
            return this.$store.state.main.loading
        },

    }

}
</script>
