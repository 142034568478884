<template>
<Forgot />
</template>

<script>
import Forgot from '../components/ForgotPage'

export default {
    components: {
        Forgot
    }
}
</script>
